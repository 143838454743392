import React from "react";
import "./Testimonials.css";

const Testimonials = () => {
    const testimonials = [
        {
          id: 1,
          name: "Amit Sharma",
          feedback: "This service is outstanding! Highly recommend it to everyone.",
          image: "https://tse4.mm.bing.net/th?id=OIP.JrEVsYvYfngm0CISVmX7YwHaE4&pid=Api&P=0&h=180", // Replace with actual image URL
        },
        {
          id: 2,
          name: "Priya Mehta",
          feedback: "Absolutely satisfied with the customer support and experience.",
          image: "https://opensource.com/sites/default/files/2023-03/IMG_4274_Original.jpg",
        },
        {
          id: 3,
          name: "Rahul Verma",
          feedback: "The experience was seamless and worth every penny.",
          image: "https://tse4.mm.bing.net/th?id=OIP.t3AAfVwruF5ZzFZTL1Jr0AHaE7&pid=Api&P=0&h=180",
        },
        {
          id: 4,
          name: "Anjali Gupta",
          feedback: "Highly reliable and great value for money. I’m impressed!",
          image: "https://tse4.mm.bing.net/th?id=OIP.7UsswXqtP9QTCiMP-FBWsQHaHa&pid=Api&P=0&h=180",
        },
        {
          id: 5,
          name: "Vikas Kumar",
          feedback: "Exceptional service with quick turnaround time. Very happy!",
          image: "https://tse2.mm.bing.net/th?id=OIP.xAnvuBQx_rGb1ZwiCRQLvgAAAA&pid=Api&P=0&w=300&h=300",
        },
        {
          id: 6,
          name: "Sneha Iyer",
          feedback: "The team is very professional, and the service exceeded my expectations.",
          image: "https://tse4.mm.bing.net/th?id=OIP.eKrUUX8xD7aQOP-8HrrX5gAAAA&pid=Api&P=0&h=180",
        },
        
        
        {
          id: 8,
          name: "Kavya Nair",
          feedback: "I am very satisfied with the overall quality. Will recommend to my friends.",
          image: "https://tse4.mm.bing.net/th?id=OIP.1B16B3yP4sPmW6tMoetiqgAAAA&pid=Api&P=0&h=180",
        },
        {
          id: 9,
          name: "Arjun Reddy",
          feedback: "They are very responsive and understand the customer’s needs perfectly.",
          image: "https://tse1.mm.bing.net/th?id=OIP.djIh-9ejyK9s3-w0To4fowAAAA&pid=Api&P=0&h=180",
        },
        {
          id: 10,
          name: "Ritika Singh",
          feedback: "Fantastic experience from start to finish. Will use their service again.",
          image: "https://tse4.mm.bing.net/th?id=OIP.rpkWOkEX6EA0fWZb5bvIbwHaHa&pid=Api&P=0&h=180",
        },
      ];
      
      
  

  return (
    <section id="testimonials" className="testimonials-section">
      <h2>What Our Customers Say</h2>
      <div className="testimonial-container">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-box">
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="testimonial-image"
            />
            <p className="testimonial-feedback">"{testimonial.feedback}"</p>
            <h4 className="testimonial-name">- {testimonial.name}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
