import React from "react";
import './style.css';
import Contact from "../../component/Contact/Contact";

const CompanyRegistrationPage = () => {
  return (
    <div className="company-registration-container">
      <h1>Company Registration Information</h1>
      <p>
        Company registration is the process of legally establishing a company
        or business entity in a specific jurisdiction. It is essential for
        starting a business as it grants your company legal status and provides
        access to various benefits such as limited liability, tax advantages, and
        protection for intellectual property.
      </p>

      <h2>What is Company Registration?</h2>
      <p>
        Company registration is the official process through which a business is
        legally recognized. By registering, you establish the company's
        existence as a separate legal entity, distinct from its owners. The
        registration process ensures that your company can operate under the law
        and is eligible for benefits such as raising capital, entering contracts,
        and more.
      </p>

      <h2>Types of Companies</h2>
      <p>
        In most countries, businesses can be registered under various types of
        company structures. Some common company types include:
      </p>
      <ul>
        <li><strong>Private Limited Company:</strong> A small or medium-sized business with limited liability for its shareholders.</li>
        <li><strong>Public Limited Company:</strong> A large company with shares that are traded on the stock market.</li>
        <li><strong>Limited Liability Partnership (LLP):</strong> A hybrid between a partnership and a company where partners have limited liability.</li>
        <li><strong>Sole Proprietorship:</strong> A business owned by a single individual with no distinction between the business and the owner.</li>
        <li><strong>Partnership:</strong> A business structure where two or more individuals share ownership and responsibilities.</li>
      </ul>

      <h2>Steps to Register a Company</h2>
      <ol>
        <li>
          <strong>Choose the Type of Company:</strong> Decide whether you want to
          register a private limited company, public limited company, LLP, or
          any other business structure.
        </li>
        <li>
          <strong>Select a Company Name:</strong> Choose a unique and suitable
          name for your company. The name should not be similar to an existing
          registered company.
        </li>
        <li>
          <strong>Prepare the Required Documents:</strong> Gather all the necessary
          documents such as identification proof, business address, etc.
        </li>
        <li>
          <strong>File the Registration Application:</strong> Submit the
          application to the relevant government authority, such as the
          Ministry of Corporate Affairs (MCA) in India or Companies House in the
          UK.
        </li>
        <li>
          <strong>Pay the Registration Fee:</strong> Pay the prescribed fee as
          required by the regulatory authority.
        </li>
        <li>
          <strong>Obtain the Certificate of Incorporation:</strong> Once your
          application is approved, you will receive a certificate of
          incorporation, which legally establishes your company.
        </li>
      </ol>

      <h2>Required Documents for Company Registration</h2>
      <ul>
        <li>Identity Proof of Directors (Aadhaar Card, Passport, etc.)</li>
        <li>Proof of Address of the Registered Office (Utility Bill, Rent Agreement, etc.)</li>
        <li>Photographs of Directors</li>
        <li>Incorporation Form (which can be filled online)</li>
        <li>Memorandum and Articles of Association (MOA & AOA)</li>
        <li>Proof of Company Name (if applicable)</li>
        <li>Director Identification Number (DIN)</li>
        <li>Digital Signature Certificate (DSC) for filing</li>
      </ul>

      <h2>Who Can Register a Company?</h2>
      <p>
        Any individual or group of individuals wishing to start a business or
        formalize an existing business can apply for company registration.
        Generally, you need at least one director and one shareholder to form a
        company, and the director(s) must be over the legal age (usually 18 years).
      </p>

      <h2>Why Register a Company?</h2>
      <p>There are many benefits to registering a company, such as:</p>
      <ul>
        <li><strong>Limited Liability:</strong> Protects personal assets from business liabilities.</li>
        <li><strong>Legal Recognition:</strong> Establishes your business as a legal entity with rights and obligations.</li>
        <li><strong>Access to Finance:</strong> Makes it easier to raise funds through loans or venture capital.</li>
        <li><strong>Brand Protection:</strong> Ensures your business name and intellectual property are legally protected.</li>
        <li><strong>Tax Benefits:</strong> Registered companies often qualify for tax deductions and credits unavailable to unregistered businesses.</li>
        <li><strong>Business Credibility:</strong> A registered company enhances your credibility with clients, suppliers, and partners.</li>
      </ul>

      <h2>Where to Register a Company?</h2>
      <p>
        You can register a company with the relevant government authority in
        your country. For example, in India, you can register a company through
        the Ministry of Corporate Affairs (MCA) website. Similarly, in the UK, you
        can register your company with Companies House.
      </p>

      <h2>Company Registration Fees</h2>
      <p>
        The registration fee depends on the type of company and jurisdiction. Below are
        typical costs:
      </p>
      <ul>
        <li><strong>Private Limited Company:</strong> ₹6,000 - ₹10,000 (in India)</li>
        <li><strong>Public Limited Company:</strong> ₹20,000 - ₹30,000 (in India)</li>
        <li><strong>LLP Registration:</strong> ₹5,000 - ₹8,000 (in India)</li>
        <li><strong>Additional Fees:</strong> The cost may vary based on state regulations or additional services like digital signatures or legal advice.</li>
      </ul>

      <h2>Tax Registration After Company Incorporation</h2>
      <p>
        Once your company is incorporated, you must apply for various tax
        registrations, including:
      </p>
      <ul>
        <li>Goods and Services Tax (GST) Registration (if applicable)</li>
        <li>Tax Deduction and Collection Account Number (TAN)</li>
        <li>Permanent Account Number (PAN) for the company</li>
      </ul>

      <Contact/>
      
    </div>
  );
};

export default CompanyRegistrationPage;
