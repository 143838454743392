import React from 'react';
import './style.css';
import Contact from '../../component/Contact/Contact';

const DrugPage = () => {
  return (
    <div className="drug-page-container">
      {/* Header Section */}
      <header className="header2">
        <h1>Essential Drugs Information</h1>
<p>
  Discover comprehensive insights into the world of essential medicines, their 
  classifications, uses, side effects, and benefits. Our goal is to empower 
  individuals with accurate and reliable drug information to make informed healthcare decisions. 
  Stay updated on the latest advancements in pharmaceuticals and learn how these drugs 
  contribute to improving lives.
</p>

      </header>

      {/* Information Section */}
      <section className="info-section">
        <h2>Drug Categories</h2>
        <div className="info-cards">
          <div className="card">
            <h3>Antibiotics</h3>
            <p>Used to treat bacterial infections and prevent their spread.</p>
          </div>
          <div className="card">
            <h3>Pain Relievers</h3>
            <p>Helps alleviate different types of pain, including chronic and acute pain.</p>
          </div>
          <div className="card">
            <h3>Vaccines</h3>
            <p>Boost immunity against various diseases and infections.</p>
          </div>
          <div className="card">
            <h3>Antiviral Drugs</h3>
            <p>Effective in treating and managing viral infections like influenza.</p>
          </div>
        </div>
      </section>

      {/* Side Effects Section */}
      <section className="side-effects-section">
        <h2>Side Effects and Warnings</h2>
        <div className="side-effects-container">
          <p>
            Some drugs may have side effects, including nausea, dizziness, or allergic reactions. It is essential to consult a healthcare provider before starting any medication. Here are some common warnings:
          </p>
          <ul>
            <li>Do not exceed the recommended dosage.</li>
            <li>Avoid combining drugs without medical advice.</li>
            <li>Check for potential allergies to ingredients.</li>
          </ul>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions (FAQs)</h2>
        <div className="faq-container">
          <div className="faq">
            <h3>What are over-the-counter (OTC) drugs?</h3>
            <p>OTC drugs are medications that can be purchased without a prescription. They are generally used for minor health issues like colds, headaches, or allergies.</p>
          </div>
          <div className="faq">
            <h3>How do I know the correct dosage for a drug?</h3>
            <p>Always read the drug label carefully and follow the dosage instructions. Consult your doctor or pharmacist if you're unsure.</p>
          </div>
          <div className="faq">
            <h3>Are generic drugs safe?</h3>
            <p>Yes, generic drugs are FDA-approved and considered as effective as their branded counterparts.</p>
          </div>
        </div>
      </section>

      {/* Ads Section */}
      <section className="ads-section">
        <h2>Promotional Content</h2>
        <div className="ad">
          <img src="https://th.bing.com/th/id/OIP.QKY7uzwFEWG29_qMXi3ODwHaFT?rs=1&pid=ImgDetMain" alt="Promotional Ad" />
        </div>
      </section>

      {/* Form Section */}
      <section className="form-section">
        <div className="form-container">
          <h2>Request Drug Information</h2>
        
        </div>
      </section>
      <Contact/>

    
    </div>
  );
};

export default DrugPage;
