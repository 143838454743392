import React from "react";
import "./style.css";
import Contact from "../../component/Contact/Contact";

const DeathCertificate = () => {
  return (
    <section id="death-certificate" className="certificate-section">
      <div className="certificate-container">
        <div className="certificate-details">
          <h1 className="certificate-title">Death Certificate</h1>

          <p className="description">
            A death certificate is an official document issued by the government to record the date, location, and cause of a person's death. It is a critical document required for legal purposes, including settling the deceased's estate, claiming life insurance, and other legal formalities. The certificate serves as a legal proof of death and is essential for family members to proceed with financial or property matters.
          </p>
          <ul className="certificate-list">
            <li>Records the date, location, and cause of death.</li>
            <li>Required for legal purposes such as settling estates.</li>
            <li>Used for claiming life insurance and pension benefits.</li>
          </ul>

          <p className="description">
            In most countries, obtaining a death certificate is mandatory, and it must be registered within a specific time frame, usually within 21 days of the death. The registration process involves submitting the necessary documents, including a medical certificate of cause of death issued by a doctor or hospital and an application form to the local municipal authority or registrar's office.
          </p>
          <ul className="certificate-list">
            <li>Death must be registered within a stipulated time frame.</li>
            <li>Medical certificate of cause of death is required.</li>
            <li>Application must be submitted to local municipal authorities.</li>
          </ul>

          <p className="description">
            Digital services now allow for the online application and tracking of death certificates. This process makes it more convenient for family members to obtain the certificate without frequent visits to government offices. Applicants can log in to the respective state or country's official portal, fill out the required forms, upload supporting documents, and track the application status online.
          </p>
          <ul className="certificate-list">
            <li>Online portals simplify the application process.</li>
            <li>Applicants can upload documents and track the application status.</li>
            <li>Digital certificates can be downloaded directly from the portal.</li>
          </ul>

          <p className="description">
            A death certificate is not only a legal requirement but also an essential document for maintaining accurate public records. It helps governments analyze mortality rates, identify causes of death, and implement public health policies. Therefore, registering a death promptly is both a legal obligation and a social responsibility.
          </p>
          <ul className="certificate-list">
            <li>Helps maintain accurate public records.</li>
            <li>Supports analysis of mortality rates and health policies.</li>
            <li>Registration is a legal obligation and social responsibility.</li>
          </ul>

          <Contact />
        </div>
      </div>
    </section>
  );
};

export default DeathCertificate;
