import React, { useEffect } from "react";
import "./style.css";
import Services from "../../component/Services/service";
import Contact from "../../component/Contact/Contact";
import NetworkPartners from "../../component/NetworkPartners/NetworkPartners";

const Service = () => {
  useEffect(() => {
    // Add animation on scroll
    const handleScroll = () => {
      const elements = document.querySelectorAll(".service-card");
      elements.forEach((el) => {
        const position = el.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (position < windowHeight - 100) {
          el.classList.add("animate");
        } else {
          el.classList.remove("animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const services = [
    {
      title: "Web Development",
      description: "We build responsive and modern websites tailored to your needs.",
    },
    {
      title: "UI/UX Design",
      description: "Creative and user-friendly interfaces to enhance user experience.",
    },
    {
      title: "SEO Optimization",
      description: "Optimized solutions to improve your website's search engine ranking.",
    },
  ];

  return (
    <div className="service-container">
      <h1 className="service-heading"> Services</h1>
      <p className="service-subheading">
        We offer a wide range of services to help your business grow.
      </p>
      <div className="service-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
      <Services />
      <NetworkPartners/>
      
      <Contact/>
      
    </div>
  );
};

export default Service;
