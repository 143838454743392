import React from "react";
import './style.css';
import Contact from "../../component/Contact/Contact";
import Testimonials from "../../component/Testimonials/Testimonials";

const BirthCertificatePage = () => {
  return (
    <div className="birth-certificate-container">
      <h1>Birth Certificate Information</h1>
      <p>
        A birth certificate is an official document issued by the government
        that records the birth of a child. It is an essential document used for
        various purposes, including applying for a passport, enrolling in school,
        and other legal purposes. Here’s everything you need to know about
        obtaining a birth certificate.
      </p>

      <h2>Steps to Obtain a Birth Certificate</h2>
      <ol>
        <li>
          <strong>Visit the Local Civil Registration Office:</strong> You need
          to visit the nearest office in your area (usually the local municipal
          or district office).
        </li>
        <li>
          <strong>Fill Out the Application Form:</strong> Obtain and complete
          the birth certificate application form with necessary details.
        </li>
        <li>
          <strong>Submit Required Documents:</strong> Submit supporting
          documents such as the hospital record, parent identification, etc.
        </li>
        <li>
          <strong>Verification Process:</strong> The authorities will verify the
          details provided and check the validity of the application.
        </li>
        <li>
          <strong>Issuance of the Birth Certificate:</strong> After verification,
          the birth certificate will be issued and handed over to the applicant.
        </li>
      </ol>

      <h2>Required Documents</h2>
      <ul>
        <li>Hospital/Institution Birth Record</li>
        <li>Parents’ Identity Proof (Passport, Voter ID, etc.)</li>
        <li>Proof of Address (Aadhaar card, utility bills, etc.)</li>
        <li>Form of Application (filled out and signed)</li>
        <li>Photocopy of the Mother’s/Father’s ID</li>
        <li>Declaration/Certificate from a Doctor or Midwife (if applicable)</li>
      </ul>

      <h2>Who Can Apply for a Birth Certificate?</h2>
      <p>
        The parents of the child, legal guardians, or authorized representatives
        can apply for a birth certificate. If the child is above a certain age, they
        may apply for themselves, depending on the jurisdiction.
      </p>

      <h2>Why is a Birth Certificate Important?</h2>
      <p>
        A birth certificate is one of the most essential documents. It is required
        for several official processes, such as:
      </p>
      <ul>
        <li>School Admissions</li>
        <li>Passport and Visa Applications</li>
        <li>Government Scheme Benefits</li>
        <li>Proof of Age for Employment</li>
        <li>Social Security Benefits</li>
      </ul>

      <h2>Where to Apply for a Birth Certificate?</h2>
      <p>
        You can apply for a birth certificate at the following locations:
      </p>
      <ul>
        <li>Municipal Corporation or Local Body Office</li>
        <li>District Court (if the birth occurred in a non-registered area)</li>
        <li>Online Portals (in some jurisdictions)</li>
      </ul>

     
      <p>
        If you need further assistance or details, you can contact the nearest
        civil registration office. They provide all the information regarding
        the application process, fees, and submission details.
      </p>
      <Testimonials/>

       <Contact/>

    </div>
  );
};

export default BirthCertificatePage;
