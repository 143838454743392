import React from 'react';
import './style.css';
import "../../assets/watermarked_preview.mp4";

function AboutDeenisLawChamber() {
  return (
    <section id="about-deenis-law-chamber" className="section">
      <div className="about-container">
        <h1>About Us</h1>
        <p>
          At Deenis Law Chamber, we pride ourselves on offering comprehensive legal services tailored to meet the needs of our clients. Our team of seasoned attorneys is equipped to handle a wide array of legal issues, from family law and criminal defense to property disputes, corporate law, and civil litigation. We understand that dealing with legal matters can be overwhelming, which is why we strive to make the process as transparent and stress-free as possible.
        </p>

        <p>
          Whether you're facing a complex divorce case, seeking advice on property disputes, or need assistance with business transactions, we offer expert legal counsel that ensures you understand your options and the best path forward. We have a reputation for delivering results with integrity, compassion, and professionalism.
        </p>

        <p>
          In addition to traditional legal services, we specialize in alternative dispute resolution methods such as mediation and arbitration, which can help resolve disputes outside of court, saving both time and costs for our clients. Our lawyers are committed to protecting your rights and working tirelessly on your behalf to achieve favorable outcomes.
        </p>

        <p>
          We believe in empowering our clients with the information they need to make informed decisions about their legal matters. Our team is always available for consultation, and we are dedicated to offering support at every stage of your legal journey. Let us be your trusted legal partner, helping you navigate even the most challenging situations with confidence.
        </p>

        <p>
          At Deenis Law Chamber, we are also passionate about providing proactive legal strategies for our clients. We offer consultations to help individuals and businesses address potential legal issues before they escalate. Our team provides detailed assessments and advice, helping our clients mitigate legal risks in both personal and professional matters.
        </p>

        <p>
          Our commitment to client satisfaction is reflected in our approach to personalized service. We take the time to listen to your concerns and tailor our advice to suit your specific needs. Whether it's handling sensitive family matters, resolving complex commercial disputes, or guiding you through intricate legal processes, our priority is always to secure the best possible outcome for you.
        </p>

        <p>
          We recognize that every client is unique, and that's why we offer flexible consultation schedules and prioritize timely responses to your inquiries. At Deenis Law Chamber, you are not just a case file; you are a valued partner, and we are here to advocate on your behalf, ensuring your legal rights are always protected.
        </p>

        <p>
          Our team also stays at the forefront of legal developments and new regulations to provide the most up-to-date and effective solutions. This allows us to serve you with confidence, knowing we are offering the most informed and thorough legal advice available. No matter what legal matter you're facing, we will provide the support and expertise you need to navigate your case successfully.
        </p>

        <p>
          Let Deenis Law Chamber be your first choice for legal support, where professionalism meets empathy, and your legal journey is made as smooth as possible.
        </p>
        <p>
          Our team also stays at the forefront of legal developments and new regulations to provide the most up-to-date and effective solutions. This allows us to serve you with confidence, knowing we are offering the most informed and thorough legal advice available. No matter what legal matter you're facing, we will provide the support and expertise you need to navigate your case successfully.
        </p>

        <p>
          Let Deenis Law Chamber be your first choice for legal support, where professionalism meets empathy, and your legal journey is made as smooth as possible.
        </p>
      </div>

     
    </section>
  );
}

export default AboutDeenisLawChamber;
