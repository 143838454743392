import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import Contact from '../../component/Contact/Contact';

const FSSAILicense = () => {
  return (
    <section id="fssai-license" className="fssai-section">
      <div className="fssai-container">
        <div className="fssai-details">
          <img
            src="https://pinnacle.org.in/wp-content/uploads/2024/05/FSSAI-License-img1.png"
            alt="FSSAI License"
            className="fssai-image"
          />
          <h1>FSSAI License</h1>
          <p className="description">
            The Food Safety and Standards Authority of India (FSSAI) is a statutory body responsible for regulating and overseeing food safety in India. It is mandatory for all food-related businesses in India to obtain an FSSAI License to ensure that food products are safe and meet the required hygiene standards.
          </p>

          <h3>Types of FSSAI Licenses:</h3>
          <ul>
            <li><strong>Basic Registration:</strong> For small food businesses with a turnover of up to ₹12 lakhs annually.</li>
            <li><strong>State License:</strong> For medium-sized food businesses with a turnover between ₹12 lakhs and ₹20 crores annually.</li>
            <li><strong>Central License:</strong> For large food businesses with a turnover exceeding ₹20 crores or businesses operating across multiple states.</li>
          </ul>

          <h3>Documents Required for FSSAI Registration:</h3>
          <ul>
            <li>Identity Proof (Aadhar card, PAN card)</li>
            <li>Address Proof (Electricity bill, lease agreement)</li>
            <li>Photograph of the applicant</li>
            <li>Business documents (Certificate of Incorporation, GSTIN, etc.)</li>
            <li>Food Safety Management Plan (if applicable)</li>
          </ul>

          <h3>Benefits of FSSAI Registration:</h3>
          <ul>
            <li>Legal Compliance and Certification for Food Safety</li>
            <li>Improved Customer Trust and Confidence</li>
            <li>Enhanced Business Reputation and Growth</li>
            <li>Access to National and International Markets</li>
            <li>Ensures Health and Hygiene Standards</li>
          </ul>

          <h3>Process to Obtain FSSAI License:</h3>
          <ol>
            <li>Determine the Type of FSSAI License Required (Basic, State, or Central).</li>
            <li>Prepare and Gather Required Documents.</li>
            <li>Submit Application Online via the FSSAI Portal or through a Professional.</li>
            <li>Wait for Verification and Approval by FSSAI Authorities.</li>
            <li>Receive the FSSAI License and Begin Operations Legally.</li>
          </ol>

        <Contact/>
        </div>
      </div>
    </section>
  );
};

export default FSSAILicense;
