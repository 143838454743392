import React from "react";
import './style.css';
import Contact from "../../component/Contact/Contact";

const DocumentationAndAttestationPage = () => {
  return (
    <div className="documentation-attestation-container">
      <h1>Documentation and Attestation Services</h1>
      <img
        src="https://legalfirm.in/wp-content/uploads/2019/04/Attestation.jpg"
        alt="Documentation and Attestation"
        className="documentation-image"
      />
      <p>
        Documentation and attestation services are vital for validating official
        documents, ensuring their legality, and making them acceptable in various
        jurisdictions. These services help individuals and organizations complete
        formalities related to international or domestic processes, including
        marriage, education, business, and immigration.
      </p>

      <h2>What is Document Attestation?</h2>
      <p>
        Document attestation is the process of certifying a document by the
        relevant authorities to confirm its authenticity. Attestation is often
        required when submitting documents for legal purposes, such as visa
        applications, job applications, or business transactions across borders.
        It confirms that the document is genuine and that it has been issued by
        the proper authority.
      </p>

      <h2>Types of Documents That Require Attestation</h2>
      <ul>
        <li><strong>Educational Documents:</strong> Degrees, diplomas, transcripts, etc.</li>
        <li><strong>Birth and Marriage Certificates:</strong> Required for immigration, legal purposes, or name changes.</li>
        <li><strong>Commercial Documents:</strong> Business contracts, power of attorney, etc.</li>
        <li><strong>Legal Documents:</strong> Affidavits, court orders, etc.</li>
        <li><strong>Medical Documents:</strong> Health certificates for immigration or job applications.</li>
      </ul>

      <h2>Steps for Document Attestation</h2>
      <ol>
        <li>
          <strong>Step 1:</strong> <strong>Prepare the Document:</strong> Ensure the document you want to be attested is complete and accurate.
        </li>
        <li>
          <strong>Step 2:</strong> <strong>Submit the Document:</strong> Submit the document to the relevant authorities or attestation agencies.
        </li>
        <li>
          <strong>Step 3:</strong> <strong>Authentication:</strong> The document is authenticated by the notary or other authorized officials.
        </li>
        <li>
          <strong>Step 4:</strong> <strong>Approval by the Relevant Authority:</strong> The final attestation is performed by the embassy or ministry, depending on the destination country.
        </li>
        <li>
          <strong>Step 5:</strong> <strong>Receive the Attested Document:</strong> After completion, you will receive the attested document, ready for use.
        </li>
      </ol>

      <h2>Types of Attestation Services</h2>
      <ul>
        <li><strong>Embassy Attestation:</strong> For documents that require legalization for use in foreign countries.</li>
        <li><strong>Notary Attestation:</strong> A basic attestation by a licensed notary for minor legal matters.</li>
        <li><strong>MEA Attestation:</strong> Ministry of External Affairs attestation for documents meant for international use.</li>
        <li><strong>State Government Attestation:</strong> For documents requiring validation by state authorities.</li>
      </ul>

      <h2>Why is Document Attestation Important?</h2>
      <p>
        Document attestation is essential for verifying the authenticity of your
        documents, especially when they are to be used for international or legal
        purposes. Without proper attestation, your documents may not be accepted
        by authorities or institutions, delaying your application or business
        process.
      </p>

      <h2>Common Uses of Attested Documents</h2>
      <ul>
        <li><strong>Visa Applications:</strong> Required by embassies when applying for visas.</li>
        <li><strong>Job Applications:</strong> Employers may require attested academic or professional certificates.</li>
        <li><strong>Immigration:</strong> Attested documents are essential for various immigration processes.</li>
        <li><strong>Legal Formalities:</strong> Attested documents may be needed for legal purposes, such as property transfers or court cases.</li>
      </ul>

      <h2>Attestation Services for Different Countries</h2>
      <p>
        Different countries have specific attestation requirements. Some countries
        require attestation by the Ministry of External Affairs (MEA) or embassies,
        while others may accept documents attested by a notary. Below are some common
        attestation requirements by country:
      </p>
      <ul>
        <li><strong>India:</strong> Attestation by the Ministry of External Affairs (MEA), State Government, or embassy.</li>
        <li><strong>United States:</strong> Notary attestation, followed by embassy attestation for documents meant for international use.</li>
        <li><strong>UAE:</strong> Documents need to be attested by the UAE embassy or consulate in your home country.</li>
        <li><strong>Saudi Arabia:</strong> Documents must be attested by the Saudi Arabian embassy and MEA.</li>
      </ul>

      <h2>How Long Does Document Attestation Take?</h2>
      <p>
        The time taken for document attestation depends on the type of document, the
        attesting authority, and the country where the document will be used. The
        process can take anywhere from a few days to a few weeks.
      </p>

      <h2>Contact Information</h2>
      <p>
        If you need assistance with your document attestation process, please contact
        our expert team. We offer fast and reliable attestation services for all your
        documents.
      </p>
      
      <Contact/>
    </div>
  );
};

export default DocumentationAndAttestationPage;
