import React, { useEffect } from "react";
import OnlineConsultant from "../../component/OnlineConslatant/onlineconslant";
import Contact from "../../component/Contact/Contact";
import Testimonials from "../../component/Testimonials/Testimonials";

const OnlineConsultants=()=>{
  
  return (
    <div className="resignation-info-container">
      {/* Online Consultant Component */}
      <OnlineConsultant />
      <Testimonials/>
      {/* Contact Section */}
      <Contact />
    </div>
  );
};

export default OnlineConsultants;
