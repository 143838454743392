import React from 'react';
import './style.css';

const MoneyRecovery = () => {
  return (
    <div className="money-recovery-container">
      <header className="header1">
        <h1>Recovery of Money</h1>
        <p>Learn how to recover money, whether it's from unpaid debts, loans, or services rendered.</p>
      </header>

      <section className="intro">
        <h2>What is Money Recovery?</h2>
        <p>
          Money recovery refers to the process of retrieving unpaid money owed to an individual or business. It can involve debt collection through legal or non-legal means, depending on the situation. This may include unpaid loans, bills, fees, or outstanding invoices.
        </p>
        <p>
          There are various ways to recover money, including informal negotiations, sending demand letters, or pursuing legal action through court or arbitration. It is important to understand the recovery process to protect your rights and interests.
        </p>
        <img
          src="https://i.ytimg.com/vi/mZ9j0-yVxYw/maxresdefault.jpg"
          alt="Money Recovery"
          className="money-recovery-image"
        />
      </section>

      <section className="methods">
        <h2>Methods of Money Recovery</h2>
        <p>There are several methods of recovering money owed to you:</p>
        <div className="recovery-methods">
          <div className="method">
            <h3>1. Informal Negotiation</h3>
            <p>
              The first step in the recovery process often involves informal negotiation. This may include speaking directly with the debtor or sending a reminder notice.
            </p>
          </div>
          <div className="method">
            <h3>2. Demand Letter</h3>
            <p>
              A formal demand letter is a legal notice that requests payment within a specific period. It typically outlines the amount owed and the consequences if the payment is not made.
            </p>
          </div>
          <div className="method">
            <h3>3. Debt Collection Agency</h3>
            <p>
              If informal attempts fail, you may consider engaging a debt collection agency. These agencies specialize in recovering money and may charge a fee or a percentage of the amount recovered.
            </p>
          </div>
          <div className="method">
            <h3>4. Legal Action</h3>
            <p>
              As a last resort, legal action may be taken in court. This process involves filing a case to recover the debt. The court will issue a judgment that legally obligates the debtor to pay.
            </p>
          </div>
        </div>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>1. How long can I wait to recover the money?</h3>
          <p>
            The time you can wait depends on the statute of limitations in your jurisdiction. In many cases, this period ranges from 3 to 6 years. It is important to act quickly to ensure that you don't lose the right to recover your money.
          </p>
        </div>
        <div className="faq-item">
          <h3>2. What if the debtor refuses to pay?</h3>
          <p>
            If the debtor refuses to pay, you can pursue formal collection methods such as sending a demand letter, involving a debt collection agency, or taking legal action in court.
          </p>
        </div>
        <div className="faq-item">
          <h3>3. Can I recover money without going to court?</h3>
          <p>
            Yes, in many cases, money can be recovered without going to court. This can be done through informal negotiations or by engaging a debt collection agency. Legal action should only be considered when all other options fail.
          </p>
        </div>
      </section>

      <a href="/book-consultant">
        <button className="btn">Apply for Consultation</button>
      </a>
    </div>
  );
};

export default MoneyRecovery;
