import React from 'react';
import './style.css';

const FamilyDispute = () => {
  return (
    <div className="family-dispute-container">
      <header className="header1">
        <h1>Family Dispute</h1>
        <p>Understanding and resolving family disputes legally</p>
      </header>

      <section className="intro">
        <h2>What is a Family Dispute?</h2>
        <p>
          Family disputes refer to disagreements or conflicts that arise within a family. These disputes can involve matters such as inheritance, child custody, divorce, property division, and more. Such disputes can be emotionally charged and may require legal intervention for resolution.
        </p>
        <p>
          Resolving family disputes often involves mediation, negotiation, or, if necessary, litigation. It is important to approach these matters with care to protect family relationships and ensure a fair outcome for all involved parties.
        </p>
        <img 
          src="https://th.bing.com/th/id/OIP.Ed-PBZx3TSV1SRBf5_czygHaEc?rs=1&pid=ImgDetMain" 
          alt="Family Dispute"
          className="family-image"
        />
      </section>

      <section className="types">
        <h2>Types of Family Disputes</h2>
        <div className="dispute-types">
          <div className="type">
            <h3>1. Inheritance Disputes</h3>
            <p>
              Inheritance disputes often arise when family members disagree over the distribution of a deceased relative's estate. These disputes can involve challenges to the will or disagreements over the division of assets.
            </p>
          </div>
          <div className="type">
            <h3>2. Child Custody</h3>
            <p>
              Child custody disputes arise in divorce or separation cases where parents disagree on who should have custody of their children. Legal proceedings may involve determining the best interests of the child.
            </p>
          </div>
          <div className="type">
            <h3>3. Divorce and Property Division</h3>
            <p>
              Divorce can lead to disputes over the division of property, assets, and spousal support. Legal guidance is essential to ensure that property is divided fairly and that each party's rights are protected.
            </p>
          </div>
          <div className="type">
            <h3>4. Domestic Violence</h3>
            <p>
              Domestic violence disputes involve allegations of abuse or violence within the family. These disputes require immediate legal attention and may involve restraining orders, child protection, and other legal measures.
            </p>
          </div>
        </div>
      </section>

      <section className="process">
        <h2>Process of Resolving Family Disputes</h2>
        <div className="steps">
          <div className="step">
            <h3>Step 1: Mediation</h3>
            <p>
              Mediation is often the first step in resolving family disputes. A neutral third party helps facilitate communication between the involved parties and attempts to reach a mutually agreeable resolution.
            </p>
          </div>
          <div className="step">
            <h3>Step 2: Negotiation</h3>
            <p>
              If mediation fails, the parties may enter into negotiations to settle the dispute. This process involves direct discussions between the parties or their attorneys to find a resolution outside of court.
            </p>
          </div>
          <div className="step">
            <h3>Step 3: Litigation</h3>
            <p>
              If mediation and negotiation do not result in a resolution, litigation may be necessary. This involves taking the dispute to court, where a judge will make a final decision on the matter.
            </p>
          </div>
        </div>
      </section>

      <section className="important-info">
        <h2>Important Information</h2>
        <p>
          Family disputes can be complex and emotionally charged. It is crucial to have professional legal assistance to guide you through the process. Whether it's a matter of inheritance, child custody, or domestic violence, the right legal representation can help ensure a fair and just resolution.
        </p>
        <p>
          Many family disputes can be resolved without going to court, but sometimes litigation is necessary. A skilled family lawyer can help you navigate the legal system and protect your rights throughout the process.
        </p>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>1. How do I resolve a family dispute without going to court?</h3>
          <p>
            Family disputes can often be resolved through mediation or negotiation, where both parties agree to work together with the help of a third party or their respective attorneys.
          </p>
        </div>
        <div className="faq-item">
          <h3>2. What should I do if I am a victim of domestic violence?</h3>
          <p>
            If you are experiencing domestic violence, it is important to seek immediate help. This may include contacting law enforcement, seeking a restraining order, or seeking support from a domestic violence shelter.
          </p>
        </div>
        <div className="faq-item">
          <h3>3. How is property divided during a divorce?</h3>
          <p>
            Property division during a divorce typically follows the laws of the jurisdiction where the divorce is filed. In many cases, property is divided equitably, meaning fairly but not necessarily equally, based on various factors such as the length of the marriage and the contributions of each spouse.
          </p>
        </div>
      </section>

      <a href="/book-consultant">
        <button className="btn">Consult a Lawyer</button>
      </a>
    </div>
  );
};

export default FamilyDispute;
