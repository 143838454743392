import React from 'react';
import './style.css';

const FeaturePage = () => {
  return (
    <div className="feature-page">
      <header className="feature-header">
        <h1>Our Legal Services</h1>
        <p>Providing professional legal services to help with your legal needs.</p>
      </header>

      <section className="feature-section">
        <h2>Why Choose Us?</h2>
        <div className="feature-cards">
          <div className="feature-card">
            <h3>Experienced Lawyers</h3>
            <p>Our lawyers have decades of experience in handling all types of legal cases.</p>
          </div>
          <div className="feature-card">
            <h3>Affordable Pricing</h3>
            <p>We offer legal services at competitive and transparent prices with no hidden fees.</p>
          </div>
          <div className="feature-card">
            <h3>Fast Legal Assistance</h3>
            <p>Get timely responses and quick resolutions to your legal concerns.</p>
          </div>
        </div>
      </section>

      <section className="feature-details">
        <h2>What We Offer</h2>
        <p>We specialize in providing comprehensive legal solutions for individuals and businesses alike. Below are the core areas of our practice.</p>

        <div className="details-list">
          <div className="details-item">
            <h3>Divorce Services</h3>
            <p>We provide expert legal guidance for individuals going through a divorce, ensuring a fair and smooth process.</p>
          </div>
          <div className="details-item">
            <h3>Property Disputes</h3>
            <p>Whether it's property ownership, disputes, or inheritance, we help you resolve conflicts effectively.</p>
          </div>
          <div className="details-item">
            <h3>Criminal Defense</h3>
            <p>We offer skilled criminal defense attorneys to represent clients in various criminal cases.</p>
          </div>
          <div className="details-item">
            <h3>Family Law</h3>
            <p>We handle family-related legal matters, including child custody, adoption, and family mediation.</p>
          </div>
        </div>
      </section>

      <section className="testimonials">
        <h2>Client Testimonials</h2>
        <div className="testimonial-cards">
          <div className="testimonial-card">
            <p>"Deenis Law Chamber helped me navigate through a tough divorce. Their team was compassionate and efficient. Highly recommend!"</p>
            <h4>- Rakesh Singh</h4>
          </div>
          <div className="testimonial-card">
            <p>"I had a property dispute, and Deenis Law Chamber provided me with excellent legal counsel. The case was resolved smoothly and swiftly."</p>
            <h4>- Anjali Sharma</h4>
          </div>
          <div className="testimonial-card">
            <p>"Professional and reliable, Deenis Law Chamber helped me with my criminal defense case, and I’m grateful for their dedicated service."</p>
            <h4>- Vijay Kumar</h4>
          </div>
        </div>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions (FAQs)</h2>
        <div className="faq-list">
          <div className="faq-item">
            <h3>What are the charges for your legal services?</h3>
            <p>Our fees vary depending on the complexity of the case. We offer transparent pricing and provide a free initial consultation.</p>
          </div>
          <div className="faq-item">
            <h3>How long does a typical case take to resolve?</h3>
            <p>Case duration varies based on the type of case. We will give you an estimated timeline after understanding the specifics of your case.</p>
          </div>
          <div className="faq-item">
            <h3>Can I consult with a lawyer remotely?</h3>
            <p>Yes, we offer online legal consultations for clients who cannot visit our office in person.</p>
          </div>
        </div>
      </section>

     
    </div>
  );
};

export default FeaturePage;
