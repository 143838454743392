import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-sections">
                <div className="footer-column footer-contact">
                    <h3>Contact Us</h3>
                    <p>Phone: <a href="tel:+91926558488">+91 926558488</a></p>
                    <p>Email: <a href="mailto:Deenis.group2000@gmail.com">Deenis.group2000@gmail.com</a></p>
                    <p>
                    B 115 ,1st floor ,plot no B-8, <br/>GDITL TOWER, NSP, Pitampura, <br/> Delhi -110034
                    </p>
                </div>
                <div className="footer-column">
                    <h3>Online Legal Consultation</h3>
                    <ul>
                        <li><Link to="/divorce">Divorce</Link></li>
                        <li><Link to="/property">Property Dispute</Link></li>
                        <li><Link to="/employment">Employment Matters</Link></li>
                        <li><Link to="/recovery">Recovery of Money</Link></li>
                        <li><Link to="/Criminal">Criminal Matters</Link></li>
                        <li><Link to="/family">Family Dispute</Link></li>
                        <li><Link to="#onlineconsultant">View All</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Other Featured Services</h3>
                    <ul>
                        <li><Link to="/court">Court Marriage</Link></li>
                        <li><Link to="/birth">Birth Certificate</Link></li>
                        <li><Link to="/succession">Succession Certificate</Link></li>
                        <li><Link to="/legalheir">Legal Heir Certificate</Link></li>
                        <li><Link to="/namechange">Name Change</Link></li>
                        <li><Link to="/traffic">Traffic Challan</Link></li>
                        <li><Link to="/death">Death Certificate</Link></li>
                        <li><Link to="/DrugLicense">Drug License</Link></li>
                        <li><Link to="/sale">Sale Deed</Link></li>
                        <li><Link to="/business-license">Business License</Link></li>
                        <li><Link to="#services">View All</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Court Marriage Service Cities</h3>
                    <ul>
                        <li><Link to="#">Delhi</Link></li>
                        <li><Link to="#">Gurugram</Link></li>
                        <li><Link to="#">Haryana</Link></li>
                        <li><Link to="#">Lucknow</Link></li>
                        <li><Link to="#">Varanasi</Link></li>
                        <li><Link to="#">Rajasthan</Link></li>
                        <li><Link to="#">Bhopal</Link></li>
                        <li><Link to="#">Patna</Link></li>
                        <li><Link to="#">Agra</Link></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright © 2021-2024 Deenis Law Chamber | All Rights Reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
