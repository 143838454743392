import React from "react";
import "./NetworkPartners.css";

const NetworkPartners = () => {
  const partners = [
    { id: 1, logo: "https://tse2.mm.bing.net/th?id=OIP.LuW98c72VcRGP4WV4U609gHaCV&pid=Api&P=0&h=180" }, // Replace with actual logo URL
    { id: 2, logo: "https://tse4.mm.bing.net/th?id=OIP.I7-YvVuQ3txBPdbroaOfVQHaD3&pid=Api&P=0&h=180" },
    { id: 3, logo: "https://tradebrains.in/wp-content/uploads/2021/05/Zomato-Logo.png" },
    { id: 4, logo: "https://image.slidesharecdn.com/bigbasketppt-170803032527/95/bigbasket-ppt-1-638.jpg?cb=1501731002" },
    { id: 5, logo: "https://grafana.com/static/img/logos/logo-blinkint.png" },
    { id: 6, logo: "https://static.vecteezy.com/system/resources/previews/020/336/177/original/maruti-suzuki-logo-maruiti-icon-free-free-vector.jpg" },
  ];

  return (
    <section id="network-partners" className="network-partners-section">
      <h2>Our Network Partners</h2>
      <div className="partner-container">
        {partners.map((partner) => (
          <div key={partner.id} className="partner-logo">
            <img src={partner.logo} alt={`Partner ${partner.id}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default NetworkPartners;
