import React from 'react';
import './style.css';

const CriminalMatters = () => {
  return (
    <div className="criminal-matters-container">
      <header className="header1">
        <h1>Criminal Matters</h1>
        <p>Understand the different types of criminal matters and how to deal with them legally.</p>
      </header>

      <section className="intro">
        <h2>What are Criminal Matters?</h2>
        <p>
          Criminal matters refer to cases where a person is charged with a crime. These matters are dealt with in criminal courts, where a judge or jury determines whether the accused person is guilty or not. Criminal offenses can range from minor infractions to serious crimes like theft, assault, or murder.
        </p>
        <p>
          Legal representation is crucial in criminal matters to ensure a fair trial and defense. The criminal justice system involves law enforcement, lawyers, prosecutors, and judges working together to deliver justice.
        </p>
        <img
          src="https://th.bing.com/th/id/OIP.SLIRKW9j2TCoqdnXB2XtnwHaCe?rs=1&pid=ImgDetMain"
          alt="Criminal Matters"
          className="criminal-image"
        />
      </section>

      <section className="types">
        <h2>Types of Criminal Matters</h2>
        <div className="criminal-types">
          <div className="type">
            <h3>1. Theft</h3>
            <p>
              Theft involves the unlawful taking of someone else's property with the intent to permanently deprive them of it. This can include burglary, shoplifting, or vehicle theft.
            </p>
          </div>
          <div className="type">
            <h3>2. Assault</h3>
            <p>
              Assault is the intentional infliction of bodily harm or the threat of harm to another person. It can range from physical altercations to verbal threats.
            </p>
          </div>
          <div className="type">
            <h3>3. Murder</h3>
            <p>
              Murder is the unlawful killing of another person with intent or extreme recklessness. It is one of the most severe criminal offenses and carries significant penalties.
            </p>
          </div>
          <div className="type">
            <h3>4. Drug Offenses</h3>
            <p>
              Drug offenses refer to the illegal possession, distribution, or manufacture of controlled substances. Laws surrounding drug offenses vary by jurisdiction.
            </p>
          </div>
        </div>
      </section>

      <section className="process">
        <h2>Process of Dealing with Criminal Matters</h2>
        <div className="steps">
          <div className="step">
            <h3>Step 1: Arrest</h3>
            <p>
              The first step in a criminal matter is usually the arrest of the accused person. Law enforcement officers must have probable cause or a warrant to make an arrest.
            </p>
          </div>
          <div className="step">
            <h3>Step 2: Investigation</h3>
            <p>
              After the arrest, the investigation process begins, where evidence is collected and witnesses are questioned. The prosecutor will review the evidence to decide whether to file charges.
            </p>
          </div>
          <div className="step">
            <h3>Step 3: Trial</h3>
            <p>
              If charges are filed, the case moves to trial. Both the prosecution and defense present their arguments, and a judge or jury will determine the outcome.
            </p>
          </div>
        </div>
      </section>

      <section className="important-info">
        <h2>Important Information</h2>
        <p>
          It is essential to hire an experienced criminal lawyer who can guide you through the complexities of criminal law. Legal representation is especially important if you're facing serious charges, as a criminal conviction can have long-term consequences.
        </p>
        <p>
          Criminal matters can vary greatly in terms of severity, and the consequences of being found guilty can range from fines to imprisonment. Therefore, understanding your rights and the legal process is crucial in defending against criminal charges.
        </p>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>1. What should I do if I’m arrested?</h3>
          <p>
            If you are arrested, you have the right to remain silent and the right to an attorney. It is advisable to exercise these rights and seek legal counsel before making any statements.
          </p>
        </div>
        <div className="faq-item">
          <h3>2. Can I represent myself in a criminal case?</h3>
          <p>
            While you have the right to represent yourself, it is highly recommended to hire an attorney with expertise in criminal law. The criminal justice system can be complex, and a lawyer can help navigate it effectively.
          </p>
        </div>
        <div className="faq-item">
          <h3>3. What happens if I’m convicted?</h3>
          <p>
            If convicted, you may face penalties such as fines, probation, or imprisonment. The severity of the penalty depends on the nature of the crime and any mitigating or aggravating factors.
          </p>
        </div>
      </section>

      <a href="/book-consultant">
        <button className="btn">Consult a Lawyer</button>
      </a>
    </div>
  );
};

export default CriminalMatters;
