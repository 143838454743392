import React from "react";
import './style.css';
import Contact from "../../component/Contact/Contact";

const SuccessionCertificatePage = () => {
  return (
    <div className="succession-certificate-container">
      <h1>Succession Certificate Information</h1>
      <p>
        A succession certificate is a legal document issued by a court that
        certifies the right of an individual to inherit the property or assets
        of a deceased person. It is required for claiming the deceased's
        movable and immovable property such as bank accounts, investments, or
        property titles.
      </p>

      <h2>What is a Succession Certificate?</h2>
      <p>
        A succession certificate is issued by the court in India to the legal
        heirs of a deceased person to claim and inherit their movable
        properties like bank accounts, bonds, shares, etc. It is mandatory in
        cases where the deceased did not leave behind a will or testament.
      </p>

      <h2>Steps to Obtain a Succession Certificate</h2>
      <ol>
        <li>
          <strong>File a Petition with the Court:</strong> The interested legal
          heirs or family members must file a petition in the appropriate court
          of jurisdiction.
        </li>
        <li>
          <strong>Provide the Deceased's Details:</strong> The petition must
          include details such as the name, date of death, and place of death
          of the deceased.
        </li>
        <li>
          <strong>Provide Legal Heir Details:</strong> Details of the legal
          heirs, such as their relationship to the deceased, must be provided.
        </li>
        <li>
          <strong>Submit Proofs and Documents:</strong> Necessary documents,
          such as the death certificate, family tree, identity proofs, and
          address proofs, must be submitted.
        </li>
        <li>
          <strong>Publication of Notice:</strong> A notice regarding the
          application will be published in a local newspaper to inform any
          possible claimants.
        </li>
        <li>
          <strong>Obtain Succession Certificate:</strong> After the court's
          approval and no objections are raised, the succession certificate
          will be issued.
        </li>
      </ol>

      <h2>Required Documents</h2>
      <ul>
        <li>Death Certificate of the Deceased Person</li>
        <li>Identity Proof of the Applicant (e.g., Aadhaar Card, Passport)</li>
        <li>Proof of Relationship with the Deceased</li>
        <li>Legal Heirship Certificate (if applicable)</li>
        <li>Family Tree (Genealogical Information)</li>
        <li>Bank Statements, Property Documents, and Investments</li>
      </ul>

      <h2>Who Can Apply for a Succession Certificate?</h2>
      <p>
        The legal heirs of the deceased person, such as a spouse, children,
        parents, or siblings, can apply for a succession certificate. If there
        is no will left by the deceased, the court will determine the rightful
        heirs according to applicable succession laws.
      </p>

      <h2>Why is a Succession Certificate Important?</h2>
      <p>
        A succession certificate is required for the transfer or succession of
        assets, including:
      </p>
      <ul>
        <li>Bank Accounts and Fixed Deposits</li>
        <li>Shares, Bonds, and Securities</li>
        <li>Real Estate and Property Titles</li>
        <li>Vehicles and Insurance Policies</li>
        <li>Inheritance Claims</li>
      </ul>

      <h2>How to Apply for a Succession Certificate?</h2>
      <p>
        The process to apply for a succession certificate involves submitting a
        petition in the appropriate court, along with required documents. Once
        the petition is filed, the court will proceed with verifying the
        details and issuing the certificate if everything is in order. A
        publication notice is also issued to ensure there are no objections to
        the heirs' claim.
      </p>

      <h2>Where to Apply?</h2>
      <p>
        You can apply for a succession certificate at the local civil court or
        district court where the deceased person resided. In some cases, you
        may apply online through the state government's legal portal.
      </p>

      <h2>Contact Information</h2>
      <p>
        For further assistance and details, you can contact the nearest court
        office or visit the relevant government websites to understand the
        specific requirements for applying for a succession certificate.
      </p>
 <Contact/>
      
    </div>
  );
};

export default SuccessionCertificatePage;
