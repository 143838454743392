import React from 'react';
import './style.css';
import Contact from '../../component/Contact/Contact';

const SaleDeed = () => {
  return (
    <section id="sale-deed" className="sale-deed-section">
      <div className="sale-deed-container">
        <div className="sale-deed-details">
          <h1 className="sale-deed-title">Sale Deed</h1>

          {/* Images Section */}
          <div className="sale-deed-images">
            <img
              src="https://th.bing.com/th/id/OIP.vV9xanig47bKWqHfDhmrVgHaE9?rs=1&pid=ImgDetMain"
              alt="Property 1"
              className="sale-deed-image"
            />
            <img
              src="https://th.bing.com/th/id/OIP.paReAjTXUS5swtw135EC-AAAAA?w=300&h=200&rs=1&pid=ImgDetMain"
              alt="Property 2"
              className="sale-deed-image"
            />
           
          </div>

          {/* Sale Deed Content */}
          <p className="description">
            A Sale Deed is a legal document that is executed to transfer the ownership of property from one person (the seller) to another (the buyer). This document contains all the details regarding the property, the seller, the buyer, and the terms of the sale. It is a crucial document in real estate transactions as it legally validates the transfer of the property from one party to another.
          </p>
          <p className="description">
            The Sale Deed must be executed on a non-judicial stamp paper, and it is registered with the local sub-registrar office to make it legally binding. It includes details such as the name of the seller and buyer, the address and description of the property being sold, the agreed sale price, and payment details. Additionally, it mentions any encumbrances or liabilities associated with the property, which might affect the buyer.
          </p>
          <p className="description">
            One of the key aspects of the Sale Deed is the declaration of ownership by the seller, assuring that the property is free from any legal issues, disputes, or claims. If the seller has outstanding dues on the property, such as mortgages or unpaid taxes, it must be disclosed in the deed. The buyer’s signature is also required to confirm their acceptance of the terms and conditions of the sale.
          </p>
          <p className="description">
            The Sale Deed is typically drafted by a legal professional, who ensures that all the necessary legal formalities are followed. Once the Sale Deed is signed by both parties, it is required to be registered with the local sub-registrar office, and only after registration, the transfer of property is considered valid. The registered Sale Deed serves as proof of the transaction and can be used for future legal purposes.
          </p>
          <p className="description">
            After the execution and registration of the Sale Deed, the buyer can take possession of the property. The sale deed will also be used to update the property records in the land records department to reflect the change in ownership. It's essential for both parties to retain copies of the signed and registered Sale Deed for future reference.
          </p>
          <p className="description">
            In conclusion, a Sale Deed is a critical document in any property transaction. It ensures that the transaction is legal and provides security to both the buyer and the seller. It’s important to make sure that all the relevant details are included, and that the Sale Deed is duly executed and registered with the appropriate authorities to make the transfer of ownership legally binding.
          </p>

          <Contact />
        </div>
      </div>
    </section>
  );
};

export default SaleDeed;
