import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./component/Header/header";
import Home from "./component/Home/home";
import About from "./component/About/about";
import Services from "./component/Services/service";
import Registration from "./component/Registration/registration";
import OnlineConsultant from "./component/OnlineConslatant/onlineconslant";
import BookConsultant from "./component/BookConsultant/BookConsultant";
import  Testimonials from "./component/Testimonials/Testimonials";
import Contact from "./component/Contact/Contact";
import Footer from "./component/Footer/footer";
import Popup from "./component/PopUp/popup";
import "@fortawesome/fontawesome-free/css/all.min.css";

import FeaturePage from "./page/Feature/feature";
import CourtMarriagePage from './page/court/court';
import BirthCertificatePage from './page/Birth/birth';
import SuccessionCertificatePage from "./page/succession/succession";
import TrademarkRegistrationPage from "./page/trademark/tradmark";
import CompanyRegistrationPage from './page/company/company';
import DocumentationAndAttestationPage from './page/Doc&attestion/docum';
import FSSAILicense from './page/Fssai/fassi';
import CorporateCompliance from './page/corporate/corporate';
import GSTRegistration from "./page/GST/gst";
import CompanyRegistration from "./page/companyllp/companyllp";
import DebtRecovery from "./page/Debt/Debt";
import TrafficChallan from "./page/Traffic/Traffic";
import DeathCertificate from "./page/Death/death";
import NameChange from "./page/Name/namechange";
import SaleDeed from "./page/saledeed/sale";
import LegalHeirCertificate from "./page/Legal/legalheir";
import DivorceCertificate from "./page/Divorce/divorce";
import PropertyDispute from "./page/Property Dispute/property";
import EmploymentMatters from "./page/employment/employment";
import  MoneyRecovery from "./page/Recovery/recovery";
import CriminalMatters from "./page/Criminal Matters/Criminal";
import FamilyDispute from "./page/family/family";
import DrugPage from "./page/DrugLicense/DrugLicense";
import AboutDeenisLawChamber from "./page/aboutpage/aboutpage"
import Service from "./page/servicepage/servicepage";
import ResignationInfo from "./page/regpage/regpage";
import OnlineConsultants from"./page/OnlineConsultantpage/OnlineConsultantpage"
import BusinessLicense from "./page/businesslicense/businesslicense";

function App() {
  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <Router>
      {/* Show the popup at the top level */}
      {isPopupVisible && (
        <Popup
          title="Disclaimer"
          message="As per the rules of the Bar Council of India, law firms are not permitted to solicit work and advertise. By clicking the 'Agree' button and accessing this website. It should not be interpreted as soliciting or advertisement. The firm is not liable for any consequence of any action taken by the user relying on material/information provided under this website. In cases where the user has any legal issues, he/she in all cases must seek independent legal advice"
          onClose={handleClosePopup}
        />
      )}

      {/* Header stays persistent across all routes */}
      <Header />

      <Routes>
        {/* Define individual routes */}
        <Route
          path="/"
          element={
            <>
              <Home />
              <About />
              <Services />
              < Testimonials/>
              <Registration />
              <OnlineConsultant />
              <Contact />
            </>
          }
        />
       

       
        <Route path="/aboutpage" element={<AboutDeenisLawChamber/>}/>
        <Route path="/servicepage" element={<Service/>}/>
        <Route path="/regpage" element={<ResignationInfo/>}/>
        <Route path="/OnlineConsultantpage" element={<OnlineConsultants/>}/>
      
        
        <Route path="/book-consultant" element={<BookConsultant />} />
        <Route path="/feature" element={<FeaturePage/>}/>
        <Route path="/court" element={<CourtMarriagePage/>}/>
        <Route path="/birth" element={<BirthCertificatePage/>}/>
        <Route path="/succession" element={<SuccessionCertificatePage/>}/>
        <Route path="/tradmark" element={<TrademarkRegistrationPage/>}/>
        <Route path="/company" element={<CompanyRegistrationPage/>}/>
        <Route path="/docum" element={<DocumentationAndAttestationPage/>}/>
        <Route path="/fassi" element={<FSSAILicense/>}/>
        <Route path= "/corporate" element= {<CorporateCompliance/>}/>
        <Route path="/gst" element={<GSTRegistration/>}/>
        <Route path="/companyllp" element={<CompanyRegistration/>}/>
        <Route path= "/Debt" element={<DebtRecovery/>}/>
        <Route path="/Traffic" element={<TrafficChallan/>}/>
        <Route path="death" element={<DeathCertificate/>}/>
    <Route path="/namechange" element={<NameChange/>}/>
    <Route path="/sale" element={<SaleDeed/>}/>
    <Route path="/legalheir" element={<LegalHeirCertificate/>}/>
    <Route path="/divorce" element={<DivorceCertificate/>}/>
    <Route path="/property" element={<PropertyDispute/>}/>
    <Route path="/employment" element={<EmploymentMatters/>}/>
    <Route path="/recovery" element={< MoneyRecovery/>}/>
    <Route path="/Criminal" element={<CriminalMatters/>}/>
    <Route path="/family" element={<FamilyDispute/>}/>
    <Route path="/DrugLicense" element={<DrugPage/>}/>
    <Route path="/business-license" element={<BusinessLicense/>}/>

      </Routes>

      {/* Footer stays persistent across all routes */}
      <Footer />
    </Router>
  );
}

export default App;
