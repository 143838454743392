import React from "react";
import "./style.css";
import Resgistration from "../../component/Registration/registration";
import Contact from "../../component/Contact/Contact";

const ResignationInfo = () => {
  return (
    <div className="resignation-info-container">
      {/* Page Heading */}
      <h1 className="resignation-info-heading">Understanding the Resignation Process</h1>

      {/* Introduction Paragraph */}
      <p className="resignation-info-paragraph">
        Resigning from a position is a significant decision that requires proper planning and execution. Whether you're seeking new opportunities or making a career change, it's important to follow a professional and respectful resignation process. Below, you'll find key steps to ensure a smooth transition, along with resources to guide you.
      </p>

      {/* Key Steps Paragraph */}
      <p className="resignation-info-paragraph">
        Some essential steps include notifying your employer, serving your notice period, and ensuring a proper handover of responsibilities. By following these steps, you can leave your current role on good terms and maintain a positive professional relationship with your employer.
      </p>

      {/* Registration Section */}
      <h2 className="section-heading">Get Expert Advice</h2>
      <p className="resignation-info-paragraph">
        If you're unsure about the legalities or formalities of resigning, our platform offers expert consultants who can guide you through the process. Register below to access personalized advice and ensure that your resignation is handled professionally.
      </p>
     
      <Resgistration/>

      {/* Contact Section */}
     
      <p className="resignation-info-paragraph">
        Have questions or need further assistance? Get in touch with us using the contact form below. Our team is here to support you during your transition.
      </p>
      <Contact />
    </div>
  );
};

export default ResignationInfo;
