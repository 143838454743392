import React from "react";
import "./style.css";
import Contact from "../../component/Contact/Contact";

const NameChange = () => {
  return (
    <section id="name-change" className="name-change-section">
      <div className="name-change-container">
        <div className="name-change-details">
          <h1 className="name-change-title">Name Change Procedure</h1>

          <div className="name-change-grid">
            <div className="name-change-item">
              <h2>Why Change Your Name?</h2>
              <p className="description">
                There are various reasons why individuals may want to change their name. Common reasons include personal preference, marriage, divorce, religious conversion, or correction of a spelling mistake in official records. Name change is an official procedure, and it requires following legal processes to ensure the name is updated in all public and private records.
              </p>
            </div>

            <div className="name-change-item">
              <h2>Steps for Name Change</h2>
              <p className="description">
                The process for legally changing your name generally involves the following steps:
                <ul>
                  <li>Submit an application to the local government or court.</li>
                  <li>Provide valid reasons and required documents.</li>
                  <li>Publish a public notice in local newspapers (if required).</li>
                  <li>Attend a hearing if necessary (in some cases).</li>
                  <li>Receive an official name change certificate.</li>
                </ul>
              </p>
            </div>

            <div className="name-change-item">
              <h2>Documents Required</h2>
              <p className="description">
                The following documents are typically required for the name change process:
                <ul>
                  <li>Application Form for Name Change.</li>
                  <li>Identity Proof (Aadhar Card, Passport, Voter ID).</li>
                  <li>Address Proof (Electricity Bill, Lease Agreement).</li>
                  <li>Affidavit for Name Change.</li>
                  <li>Newspaper advertisement (if required by law).</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="name-change-remaining">
            <p className="description">
              <strong>Legal Considerations</strong>: Changing your name legally allows you to update your identity in government records, banks, schools, and other institutions. It is important to note that a name change does not alter your birth date or your nationality. All necessary authorities must be informed about your new name to avoid any discrepancies in documents or legal matters.
            </p>

            <p className="description">
              <strong>Time and Cost</strong>: The time required for a name change can vary depending on the jurisdiction and the nature of the application. In some cases, it can take up to a few weeks, while in others, it may take several months. The cost for applying for a name change also varies but typically includes application fees, court fees, and charges for newspaper publication.
            </p>
          </div>

          <Contact />
        </div>
      </div>
    </section>
  );
};

export default NameChange;
