import React from 'react';
import './style.css';

const EmploymentMatters = () => {
  return (
    <div className="employment-container">
      <header className="header1">
        <h1>Employment Matters</h1>
        <p>Everything you need to know about employment rights, contracts, and dispute resolution</p>
      </header>

      <section className="intro">
        <h2>What is Employment Law?</h2>
        <p>
          Employment law governs the relationship between employers and employees. It covers various aspects such as employee rights, workplace policies, contracts, wages, working hours, and dispute resolution. It ensures that both employers and employees adhere to legal guidelines and fair practices.
        </p>
        <img
          src="https://www.employmentmattersblog.com/wp-content/uploads/2022/01/Employment-Matters-logo.png"
          alt="Employment Law"
          className="employment-image"
        />
      </section>

      <section className="employee-rights">
        <h2>Employee Rights</h2>
        <p>
          Employees are entitled to a wide range of rights under employment law, including but not limited to:
        </p>
        <ul>
          <li>Protection from discrimination based on gender, race, religion, or other factors</li>
          <li>Right to a safe and healthy work environment</li>
          <li>Right to fair pay and equal compensation</li>
          <li>Right to parental leave and sick leave</li>
          <li>Right to join a union and collectively bargain</li>
        </ul>
        <p>
          If you believe your rights have been violated, you may have legal recourse through formal complaints or legal action.
        </p>
      </section>

      <section className="employment-contract">
        <h2>Employment Contracts</h2>
        <p>
          An employment contract is a legal agreement between an employer and an employee. It outlines the terms and conditions of employment, such as job responsibilities, salary, working hours, and duration of employment.
        </p>
        <p>
          A well-drafted employment contract provides clarity for both parties and helps prevent misunderstandings or disputes. It's important to carefully review your contract before signing and seek legal advice if needed.
        </p>
      </section>

      <section className="dispute-resolution">
        <h2>Dispute Resolution</h2>
        <p>
          Employment disputes can arise due to various reasons such as unfair dismissal, wage disputes, harassment, or workplace safety issues. It's essential to know the methods of resolving these disputes, which may include:
        </p>
        <div className="methods">
          <div className="method">
            <h3>1. Negotiation</h3>
            <p>Direct communication between the employee and employer to settle the issue amicably.</p>
          </div>
          <div className="method">
            <h3>2. Mediation</h3>
            <p>A neutral third party helps facilitate the resolution of the dispute between the two parties.</p>
          </div>
          <div className="method">
            <h3>3. Arbitration</h3>
            <p>A formal legal process where an arbitrator makes a binding decision after hearing both sides.</p>
          </div>
        </div>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>1. What is an unfair dismissal?</h3>
          <p>
            Unfair dismissal occurs when an employee is terminated from their job without a valid or legal reason. Employees are entitled to protection from unfair dismissal in many countries, and legal recourse may be available.
          </p>
        </div>
        <div className="faq-item">
          <h3>2. What should I do if I am facing workplace harassment?</h3>
          <p>
            If you are experiencing harassment in the workplace, report the issue to your employer or HR department immediately. If the issue persists, you may need to seek legal advice or file a formal complaint.
          </p>
        </div>
        <div className="faq-item">
          <h3>3. How can I resolve wage disputes with my employer?</h3>
          <p>
            Wage disputes can often be resolved through negotiation or mediation. If an agreement can't be reached, you may need to pursue legal action to recover unpaid wages.
          </p>
        </div>
      </section>

      <a href='/book-consultant' >
        <button className='btn'>Apply for Consultation</button>
      </a>
    </div>
  );
};

export default EmploymentMatters;
