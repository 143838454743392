import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="container">
        {/* Logo */}
        <div className="logo">
          <Link to="/" onClick={closeMenu}>
            <span>DEENIS</span>
            <span className="highlight">LAW CHAMBER</span>
          </Link>
        </div>

        {/* Hamburger Icon */}
        <div className="hamburger" onClick={toggleMenu}>
          {menuOpen ? (
            <i className="fa-solid fa-xmark"></i> // Close icon
          ) : (
            <i className="fa-solid fa-bars"></i> // Hamburger icon
          )}
        </div>

        {/* Navigation */}
        <nav className={`nav ${menuOpen ? "active" : ""}`}>
          <ul className="nav-links">
            <li>
              <Link to="/" onClick={closeMenu}>Home</Link>
            </li>
            <li>
              <Link to="/aboutpage" onClick={closeMenu}>About</Link>
            </li>
            <li>
              <Link to="/servicepage" onClick={closeMenu}>Services</Link>
            </li>
            <li>
              <Link to="/regpage" onClick={closeMenu}>Registration</Link>
            </li>
            <li>
              <Link to="/OnlineConsultantpage" onClick={closeMenu}>Online Consultant</Link>
            </li>
            <li>
              <Link to="#contact" onClick={closeMenu}>Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
