import React from 'react';
import './style.css';
import Contact from '../../component/Contact/Contact';

const CourtMarriagePage = () => {
  return (
    <div className="court-marriage-container">
      <h1>Court Marriage Application</h1>
      <p>
        Court marriage is a legal procedure where two individuals, regardless of their religion, caste, or nationality, can get married under the Special Marriage Act of 1954. This marriage is legally recognized across India, and the marriage certificate issued by the court holds the same legal validity as a marriage registered under any personal law.
        This process is ideal for couples who prefer a formal, legal wedding without religious or community ceremonies.
      </p>
      
      <section className="eligibility">
        <h2>Eligibility Criteria</h2>
        <p>
          In order to apply for a court marriage, both the bride and groom need to fulfill certain eligibility criteria defined under the Special Marriage Act of 1954. It ensures that both individuals meet the legal requirements to enter into a marriage. The criteria are as follows:
        </p>
        <ul>
          <li>The bride must be at least 18 years old at the time of marriage.</li>
          <li>The groom must be at least 21 years old at the time of marriage.</li>
          <li>Both individuals must be mentally sound and capable of understanding the implications of marriage.</li>
          <li>The couple should not have any prohibited degree of relationship (as defined in Indian law) with each other, such as siblings, half-siblings, or parent-child relationships.</li>
          <li>At least one of the individuals should have been residing in India for at least 30 days before applying for the marriage.</li>
        </ul>
      </section>

      <section className="required-documents">
        <h2>Required Documents</h2>
        <p>
          The following documents are necessary to complete your court marriage application. It’s important to ensure that you have these documents prepared in advance to avoid delays:
        </p>
        <ul>
          <li>Proof of Age: This can be a Birth Certificate, Passport, or any government-issued document showing the individual's age.</li>
          <li>Proof of Address: Aadhar Card, Voter ID, Passport, or any official document that can verify the address of the applicant.</li>
          <li>Photographs: Recent passport-size photographs of both the bride and groom are required.</li>
          <li>Identity Proof: A government-issued ID card such as Aadhar, Passport, or Voter ID for both parties.</li>
          <li>Marriage Proof: If the couple has been married under any other personal law previously, relevant documents must be provided.</li>
          <li>Witnesses: Two witnesses who are above 18 years of age must be present during the marriage registration. They should provide their ID and address proof.</li>
        </ul>
      </section>

      <section className="process">
        <h2>Marriage Process</h2>
        <p>
          The court marriage process involves several key steps, ensuring that both individuals are entering into the marriage voluntarily and legally. The process typically includes the following steps:
        </p>
        <ol>
          <li>Submit the application form along with the required documents to the local court. The couple should visit the court in person for this process.</li>
          <li>Once the application is submitted, the court will publish a notice in a local newspaper regarding the intended marriage. This gives an opportunity for anyone who knows any legal reasons why the marriage should not take place to object.</li>
          <li>After a period of 30 days from the publication of the notice, if no objections are raised, both the bride and groom will appear before the marriage registrar along with their witnesses.</li>
          <li>The registrar will verify all the documents and ensure that both individuals fulfill the eligibility criteria. Once confirmed, the marriage will be solemnized in front of the witnesses.</li>
          <li>The marriage certificate will be provided immediately after the marriage is registered. This certificate serves as legal proof of the marriage, which can be used for name changes, applying for a joint bank account, and other legal purposes.</li>
        </ol>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>1. How long does it take to complete the court marriage process?</h3>
          <p>
            The entire process of court marriage typically takes about 30 days from the date of submission of the application. This includes the notice period and any required verification. However, it can vary depending on the specific court and jurisdiction.
          </p>
        </div>

        <div className="faq-item">
          <h3>2. Can we apply for court marriage if one of us is from a different religion?</h3>
          <p>
            Yes, the Special Marriage Act allows individuals of different religions, castes, or nationalities to marry under the court marriage process. There is no requirement for both individuals to follow the same religion for a court marriage.
          </p>
        </div>

        <div className="faq-item">
          <h3>3. Do we need to have witnesses for the court marriage?</h3>
          <p>
            Yes, witnesses are mandatory for a court marriage. At least two witnesses, who are above 18 years old, need to be present during the marriage registration. They should provide their identity and address proofs.
          </p>
        </div>

        <div className="faq-item">
          <h3>4. Is it possible to have a court marriage in any city in India?</h3>
          <p>
            Yes, you can apply for court marriage in any city in India, as long as you or your partner have been residing in that particular jurisdiction for at least 30 days. This is required to prove your residence status.
          </p>
        </div>
      </section>

      <section className="contact">
        <h2>Contact Us</h2>
        <p>
          For any additional information or assistance regarding your court marriage application, feel free to contact us. Our team is here to help guide you through the entire process smoothly.
        </p>
        <p>Email:Deenis.group2000@gmail.com</p>
        <p>Phone: +91 926558488</p>
        
      </section>
    </div>
  );
};

export default CourtMarriagePage;
