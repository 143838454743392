import React from 'react';
import './style.css';

const PropertyDispute = () => {
  return (
    <div className="property-container">
      <header className="header1">
        <h1>Property Dispute Resolution</h1>
        <p>Everything you need to know about resolving property disputes</p>
      </header>

      <section className="intro">
        <h2>What is a Property Dispute?</h2>
        <p>
          A property dispute occurs when parties disagree over the ownership or use of real property. These disputes can arise between neighbors, family members, business partners, or even government authorities. The resolution of property disputes may involve legal action, and the process varies depending on the nature of the dispute.
        </p>
        <img
          src="https://www.nobroker.in/blog/wp-content/uploads/2022/04/Property-disputes.jpg"
          alt="Property Dispute"
          className="property-image"
        />
      </section>

      <section className="requirements">
        <h2>Documents Required</h2>
        <p>
          To resolve a property dispute, you will typically need the following documents:
        </p>
        <ul>
          <li>Proof of Ownership (e.g., Sale Deed, Title Deed)</li>
          <li>Land Survey Records (if applicable)</li>
          <li>Identity Proof of the involved parties (e.g., Aadhaar, Passport)</li>
          <li>Any relevant agreements or contracts</li>
          <li>Photographs or evidence of the disputed property</li>
          <li>Court Orders or Notices (if any)</li>
        </ul>
        <p>
          Ensure that these documents are complete and attested to avoid delays during the dispute resolution process.
        </p>
      </section>

      <section className="process">
        <h2>Process of Resolving a Property Dispute</h2>
        <div className="steps">
          <div className="step">
            <h3>Step 1: Negotiation</h3>
            <p>Start by trying to resolve the issue through discussions with the involved parties. This can help avoid lengthy and costly legal battles.</p>
          </div>
          <div className="step">
            <h3>Step 2: Mediation</h3>
            <p>If negotiations fail, mediation can be a great way to find common ground with the help of a neutral third-party mediator.</p>
          </div>
          <div className="step">
            <h3>Step 3: Legal Action</h3>
            <p>If both mediation and negotiation fail, filing a lawsuit in a court of law may be necessary to resolve the dispute legally.</p>
          </div>
        </div>
      </section>

      <section className="important-info">
        <h2>Important Information</h2>
        <p>
          It is crucial to note that property disputes can be complex, and each case may have specific requirements or challenges. Consulting a legal expert is often recommended.
        </p>
        <p>
          Property disputes may also involve other issues such as inheritance, property tax, or zoning laws. Always ensure you have all relevant legal documents in order before proceeding.
        </p>
      </section>

      <section className="faq">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>1. How long does it take to resolve a property dispute?</h3>
          <p>The time taken depends on the complexity of the dispute and the methods used (negotiation, mediation, or legal action). It can range from a few months to several years.</p>
        </div>
        <div className="faq-item">
          <h3>2. Can I resolve a property dispute without going to court?</h3>
          <p>Yes, many property disputes can be resolved through negotiation or mediation without the need for court intervention.</p>
        </div>
        <div className="faq-item">
          <h3>3. What should I do if I lose property documents?</h3>
          <p>If you lose essential property documents, you can request a certified copy from the land registry office or seek legal advice for recovery.</p>
        </div>
      </section>
      
      <a href='/book-consultant' ><button className='btn'>Apply for Consultation</button></a>
    </div>
  );
};

export default PropertyDispute;
