import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import Contact from '../../component/Contact/Contact';


const CorporateCompliance = () => {
  return (
    <section id="corporate-compliance" className="compliance-section">
      <div className="compliance-container">
        <div className="compliance-details">
          <img
            src="https://tse4.mm.bing.net/th?id=OIP.Dp88AmlhRjF9h7krYXPvBQHaD3&pid=Api&P=0&h=180"
            alt="Corporate Compliance"
            className="compliance-image"
          />
          <h1>Corporate Compliance</h1>
          <p className="description">
            Corporate compliance refers to the legal and regulatory obligations that businesses must adhere to in order to operate legally. These obligations include local, state, and national laws that regulate financial reporting, corporate governance, tax filing, and employee welfare.
          </p>

          <h3>Key Areas of Corporate Compliance:</h3>
          <ul>
            <li><strong>Corporate Governance:</strong> Ensuring proper management, transparency, and accountability in an organization.</li>
            <li><strong>Financial Reporting:</strong> Timely and accurate reporting of financial statements in compliance with accounting standards.</li>
            <li><strong>Tax Filing Compliance:</strong> Ensuring compliance with local and national tax laws.</li>
            <li><strong>Employee Welfare Compliance:</strong> Adhering to labor laws regarding employee rights, safety, and benefits.</li>
            <li><strong>Environmental Compliance:</strong> Ensuring the business meets environmental regulations to minimize environmental impact.</li>
          </ul>

          <h3>Importance of Corporate Compliance:</h3>
          <ul>
            <li>Prevents legal issues and potential penalties.</li>
            <li>Helps maintain a good reputation and brand image.</li>
            <li>Ensures business operations are ethical and responsible.</li>
            <li>Enhances stakeholder confidence in the business.</li>
            <li>Improves business efficiency and risk management.</li>
          </ul>

          <h3>Steps for Ensuring Corporate Compliance:</h3>
          <ol>
            <li>Understand all relevant laws and regulations that apply to your business.</li>
            <li>Develop internal policies and procedures to comply with these regulations.</li>
            <li>Implement regular audits to ensure compliance is maintained.</li>
            <li>Train employees on corporate compliance practices and legal obligations.</li>
            <li>Consult with legal and compliance experts to stay up-to-date with changes in regulations.</li>
          </ol>
          

          <Contact/>
        </div>
      </div>
    </section>
  );
};

export default CorporateCompliance;
