import React from 'react';
import './style.css';
import Contact from '../../component/Contact/Contact';

const DebtRecovery = () => {
  return (
    <section id="debt-recovery" className="debt-section">
      <div className="debt-container">
        <div className="debt-details">
          <img
            src="https://tse3.mm.bing.net/th?id=OIP.pARNsim_WRv1C5BEgJBjYwHaDn&pid=Api&P=0&h=180"
            alt="Debt Recovery NBFC/Bank"
            className="debt-image"
          />
          <h1>Debt Recovery for NBFCs and Banks</h1>
          <p className="description">
            Debt recovery is a crucial process for Non-Banking Financial Companies (NBFCs) and Banks to recover unpaid dues from borrowers. The process ensures financial stability and helps financial institutions maintain their operations. Debt recovery involves legal and non-legal mechanisms to collect overdue loans and secure collateral.
          </p>

          <h3>What is Debt Recovery?</h3>
          <p>
            Debt recovery refers to the process by which banks and NBFCs recover outstanding debts from individuals or businesses that have failed to repay their loans. It includes legal measures, negotiation, and settlement processes to recover the owed amount while maintaining compliance with financial regulations.
          </p>

          <h3>Debt Recovery Mechanisms:</h3>
          <ul>
            <li>
              <strong>Negotiation and Settlement:</strong> Engaging with borrowers to settle debts through mutual agreements.
            </li>
            <li>
              <strong>Legal Action:</strong> Filing cases under the SARFAESI Act, Insolvency and Bankruptcy Code (IBC), or through Debt Recovery Tribunals (DRTs).
            </li>
            <li>
              <strong>Asset Seizure:</strong> Recovery through auction or sale of collateral under legal frameworks.
            </li>
            <li>
              <strong>Third-Party Debt Collection:</strong> Engaging professional debt collection agencies.
            </li>
          </ul>

          <h3>Why Debt Recovery is Important?</h3>
          <ul>
            <li>Maintains the financial stability of banks and NBFCs.</li>
            <li>Reduces non-performing assets (NPAs).</li>
            <li>Promotes a disciplined credit environment.</li>
            <li>Ensures smooth cash flow for further lending.</li>
            <li>Prevents misuse of borrowed funds.</li>
          </ul>

          <h3>Key Legal Frameworks for Debt Recovery in India:</h3>
          <ul>
            <li>
              <strong>SARFAESI Act:</strong> Allows financial institutions to seize and auction properties of defaulters without court intervention.
            </li>
            <li>
              <strong>Debt Recovery Tribunals (DRTs):</strong> Dedicated tribunals to expedite the recovery of loans.
            </li>
            <li>
              <strong>Insolvency and Bankruptcy Code (IBC):</strong> Facilitates the resolution of stressed assets in a time-bound manner.
            </li>
            <li>
              <strong>Negotiable Instruments Act:</strong> Addresses dishonored cheques and facilitates legal recourse.
            </li>
          </ul>

          <h3>Steps in the Debt Recovery Process:</h3>
          <ol>
            <li>Identify overdue accounts and categorize them as NPAs.</li>
            <li>Send reminders and legal notices to the borrower.</li>
            <li>Negotiate and explore settlement options.</li>
            <li>Initiate legal proceedings under applicable laws (if required).</li>
            <li>Recover dues through seizure, auction, or repayment agreements.</li>
          </ol>

         <Contact/>
        </div>
      </div>
    </section>
  );
};

export default DebtRecovery;
