import React from 'react';
import './style.css';
import Contact from '../../component/Contact/Contact';

const GSTRegistration = () => {
  return (
    <section id="gst-registration" className="gst-section">
      <div className="gst-container">
        <div className="gst-details">
          <img
            src="https://tse4.mm.bing.net/th?id=OIP.zzQ9q4pAFBw9jc5ysjD1GwHaEp&pid=Api&P=0&h=180"
            alt="GST Registration"
            className="gst-image"
          />
          <h1>GST Registration</h1>
          <p className="description">
            GST (Goods and Services Tax) Registration is a process by which a business becomes registered under the GST law in India. It is mandatory for businesses with a turnover exceeding the threshold limit or engaged in inter-state supplies. GST aims to create a unified tax system, replacing multiple indirect taxes like VAT, service tax, and excise duty.
          </p>

          <h3>Who Needs GST Registration?</h3>
          <ul>
            <li>Businesses with a turnover above ₹40 lakhs (₹10 lakhs for special category states).</li>
            <li>E-commerce operators and aggregators.</li>
            <li>Service providers with turnover above ₹20 lakhs (₹10 lakhs for special category states).</li>
            <li>Individuals supplying goods inter-state.</li>
            <li>Casual taxable persons and non-resident taxable persons.</li>
          </ul>

          <h3>Benefits of GST Registration:</h3>
          <ul>
            <li>Legal recognition as a supplier of goods or services.</li>
            <li>Allows collection of GST from customers and availing of input tax credits.</li>
            <li>Helps in expanding business to interstate and e-commerce platforms.</li>
            <li>Improves compliance and transparency in the tax system.</li>
          </ul>

          <h3>Documents Required for GST Registration:</h3>
          <ul>
            <li>PAN card of the business or owner.</li>
            <li>Proof of business registration (Incorporation certificate or partnership deed).</li>
            <li>Address proof (Electricity bill, rental agreement).</li>
            <li>Aadhar card of the applicant.</li>
            <li>Bank account details (Canceled cheque or bank statement).</li>
            <li>Digital signature (DSC) for certain registrations.</li>
          </ul>

          <h3>Steps for GST Registration:</h3>
          <ol>
            <li>Visit the GST portal (www.gst.gov.in) and create a TRN (Temporary Reference Number).</li>
            <li>Login using the TRN and fill out the application form with required details.</li>
            <li>Upload necessary documents in the prescribed format.</li>
            <li>Verify application using DSC or EVC.</li>
            <li>Receive GSTIN (Goods and Services Tax Identification Number) after successful approval.</li>
          </ol>

        <Contact/>
        </div>
      </div>
    </section>
  );
};

export default GSTRegistration;
