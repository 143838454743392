import React from "react";
import "./style.css";
import Contact from "../../component/Contact/Contact";

const TrafficChallan = () => {
  return (
    <section id="traffic-challan" className="challan-section">
      <div className="challan-container">
        <div className="challan-details">
          <h1 className="challan-title">Traffic Challan</h1>

          <p className="description">
            A traffic challan is a legal document issued by the traffic police
            to penalize individuals for violating traffic rules and regulations.
            It serves as an official notice that records the offense committed
            and imposes a penalty that must be paid within a stipulated time
            frame. Common violations leading to a challan include overspeeding,
            not wearing a helmet or seatbelt, reckless driving, driving under
            the influence of alcohol, and jumping red lights. With the
            increasing digitization of services, traffic challans can now also
            be issued electronically, commonly referred to as an e-challan,
            which is generated through traffic surveillance systems, including
            CCTV cameras, and sent directly to the offender’s registered mobile
            number or email.
          </p>
          <ul className="challan-list">
            <li>Common violations include overspeeding, reckless driving, and red light jumping.</li>
            <li>Other offenses are driving without a license, seatbelt, or helmet.</li>
            <li>E-challans are issued electronically using traffic surveillance systems.</li>
          </ul>

          <p className="description">
            The introduction of e-challans has significantly streamlined the
            process of enforcing traffic laws, making it more efficient and
            transparent. It also helps reduce instances of bribery and manual
            errors by automating the entire process. Additionally, e-challans
            can be paid online, offering greater convenience to the violators.
            Payment portals allow users to check their pending challans, verify
            details, and complete payments securely using various online payment
            methods. This digital approach encourages better compliance with
            traffic rules while promoting accountability.
          </p>
          <ul className="challan-list">
            <li>E-challans reduce bribery and manual errors through automation.</li>
            <li>Online payment options include credit/debit cards, net banking, and UPI.</li>
            <li>Users can check and pay pending challans on official traffic websites.</li>
          </ul>

          <p className="description">
            Traffic challans not only act as a deterrent against violating
            traffic norms but also contribute to improving road safety.
            Adhering to traffic laws minimizes the risk of accidents and
            ensures smoother traffic flow. Moreover, prompt payment of traffic
            challans helps individuals avoid additional penalties, legal
            consequences, or the suspension of their driving license. It is the
            responsibility of every citizen to abide by traffic rules, as doing
            so not only protects their own safety but also safeguards the lives
            of other road users.
          </p>
          <ul className="challan-list">
            <li>Traffic rules improve road safety and reduce accidents.</li>
            <li>Timely payment avoids additional fines and license suspension.</li>
            <li>Compliance with laws ensures smoother traffic flow for all.</li>
          </ul>

          <p className="description">
            Following traffic rules ensures your safety and the safety of others
            on the road. It helps maintain discipline, reduces accidents, and
            promotes a smoother traffic flow. By adhering to traffic laws, you
            also avoid unnecessary legal complications and contribute to a safer
            driving environment.
          </p>

          <Contact />
        </div>
      </div>
    </section>
  );
};

export default TrafficChallan;
