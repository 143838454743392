import React from "react";
import "./style.css"; // You can add a separate CSS file for styling

const BusinessLicense = () => {
  const licenses = [
    { name: "Plastic Waste Certificate", status: "Filed" },
    { name: "Electronic Waste Certificate", status: "Filed" },
    { name: "Tyre Waste Certificate", status: "Filed" },
    { name: "Oil Waste Certificate", status: "Filed" },
    { name: "EPR Annual Compliance", status: "Filed" },
    { name: "CTE Certificate", status: "Filed" },
    { name: "CTO Certificate", status: "Filed" },
    { name: "LMPC Certificate", status: "Filed" },
    { name: "SAFTA Certificate", status: "Filed" },
    { name: "Start-up Registration", status: "Filed" },
    { name: "APEDA", status: "Filed" },
    { name: "EPCH Certificate", status: "Filed" },
    { name: "Director Nomination", status: "Filed" },
    { name: "Certificate of Origin", status: "Filed" },
    { name: "Hallmark Certificate", status: "Filed" },
    { name: "SIMS Certificate", status: "Filed" },
    { name: "PIMS Certificate", status: "Filed" },
    { name: "CIMS Certificate", status: "Filed" },
    { name: "ESIC & PF", status: "Filed" },
    { name: "Trade License (Andhra Pradesh)", status: "Filed" },
    { name: "Rera Certificate (Rajasthan)", status: "Filed" },
    { name: "IEC Certificate", status: "Filed" },
    { name: "Agmark Certificate (Jammu Kashmir)", status: "Filed" },
    { name: "MSME Certificate", status: "Filed" },
    { name: "FSSAI Certificate", status: "Filed" },
    { name: "EEPC Certificate", status: "Filed" },
    { name: "FIEO Certificate", status: "Filed" },
    { name: "Tea Board License", status: "Filed" },
    { name: "Peso Certificate", status: "R&D" },
    { name: "Drug License Form 14", status: "R&D (Half)" },
    { name: "RNI License", status: "Filed" },
    { name: "MFD License", status: "Filed" },
    { name: "CDSCO MD-14", status: "Filed" },
    { name: "IceGate Registration", status: "Filed" },
    { name: "VNO License", status: "Filed" }
  ];

  return (
    <div className="license-sheet">
      <h2 className="title">License Sheet</h2>
      <table className="responsive-table">
        <thead>
          <tr>
            <th>Licenses</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {licenses.map((license, index) => (
            <tr key={index}>
              <td>{license.name}</td>
              <td>{license.status}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Additional Information */}
      <section className="license-info">
        <h3>Important Licenses Overview</h3>
        <div className="license-details">
          <h4>EPR (Extended Producer Responsibility)</h4>
          <p>
            EPR is a policy approach in which producers take responsibility for the lifecycle of their products, including collection, recycling, and safe disposal. It ensures that companies are accountable for the environmental impact of their products after use.
          </p>

          <h4>LMPC (Lead Material Procurement Certificate)</h4>
          <p>
            LMPC is a certificate issued to ensure that the materials used in manufacturing processes are obtained through sustainable and responsible sources. It ensures compliance with environmental and ethical procurement standards.
          </p>

          <h4>VNO (Virtual Network Operator) License</h4>
          <p>
            The VNO license allows companies to operate telecommunication services under a specific set of regulatory requirements. It enables a company to provide telecom services without owning the network infrastructure.
          </p>

          <h4>BIS (Bureau of Indian Standards) Certificate</h4>
          <p>
            The BIS Certificate is awarded to products that meet the safety, quality, and performance standards set by the Bureau of Indian Standards. It is essential for ensuring that goods comply with national regulatory standards.
          </p>
        </div>
      </section>
    </div>
  );
};

export default BusinessLicense;
