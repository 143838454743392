import React from 'react';
import './style.css';
import OnlineConsultant from '../../component/OnlineConslatant/onlineconslant';

const DivorceCertificate = () => {
  return (
    <div className="divorce-container">
      {/* Header Section */}
      <header className="header1">
        <h1>Divorce Certificate</h1>
        <p>Comprehensive guide to understanding and obtaining a Divorce Certificate.</p>
      </header>

      {/* Introduction Section */}
      <section className="intro">
        <h2>What is a Divorce Certificate?</h2>
        <p>
          A Divorce Certificate is a legal document issued by the court that confirms the official dissolution of a marriage. 
          It serves as proof of a finalized divorce and is required for various legal processes such as remarrying, 
          updating marital status, or handling property settlements.
        </p>
        <p>
          This document is often necessary for procedures like passport applications, social security updates, 
          or other official documentation. It is issued after the court has finalized the divorce decree.
        </p>
        <img
          src="https://www.duasnduas.com/wp-content/uploads/2024/01/Blue-White-Minimal-Gradient-Webs-e1706081947319.jpg"
          alt="Divorce Certificate"
          className="divorce-image"
        />
      </section>

      {/* Required Documents Section */}
      <section className="requirements">
        <h2>Documents Required</h2>
        <p>To apply for a Divorce Certificate, ensure you have the following documents:</p>
        <ul>
          <li>Marriage Certificate</li>
          <li>Completed Application Form</li>
          <li>Identity Proof of both spouses (e.g., Aadhaar, Passport)</li>
          <li>Proof of Divorce Decree issued by the court</li>
          <li>Address Proof</li>
          <li>Any additional documents required by the local court</li>
        </ul>
        <p>
          Ensure all documents are properly attested and up to date to prevent delays during the application process.
        </p>
      </section>

      {/* Process Section */}
      <section className="process">
        <h2>Steps to Obtain a Divorce Certificate</h2>
        <div className="steps">
          <div className="step">
            <h3>Step 1: Submit the Application</h3>
            <p>File an application form along with the necessary documents to the court or local authorities. Include personal details and the finalized divorce details.</p>
          </div>
          <div className="step">
            <h3>Step 2: Court Review</h3>
            <p>The court will examine the documents and may conduct a hearing to finalize the divorce proceedings. Cooperation from both parties can expedite this process.</p>
          </div>
          <div className="step">
            <h3>Step 3: Issuance of Certificate</h3>
            <p>After finalizing the divorce decree, the court will issue the Divorce Certificate. Processing time may vary between jurisdictions.</p>
          </div>
        </div>
      </section>

      <a href="/book-consultant">
        <button className="btn">Apply Now</button>
      </a>

      {/* Important Information Section */}
      <section className="important-info">
        <h2>Important Information</h2>
        <p>
          A Divorce Certificate only confirms the dissolution of marriage and does not address issues like property division or child custody. 
          These matters are handled separately during divorce proceedings.
        </p>
        <p>
          Some jurisdictions may require additional documents, such as a separation agreement or proof of legal representation. 
          Always verify requirements with your local court.
        </p>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>1. How long does it take to obtain a Divorce Certificate?</h3>
          <p>Processing time varies by court and jurisdiction, typically ranging from a few days to several weeks.</p>
        </div>
        <div className="faq-item">
          <h3>2. Can I apply for a Divorce Certificate online?</h3>
          <p>Some jurisdictions allow online applications through their official websites. Check with your local court for availability.</p>
        </div>
        <div className="faq-item">
          <h3>3. What should I do if I lose my Divorce Certificate?</h3>
          <p>Apply for a duplicate certificate at the court where your divorce was finalized. You will need to provide identity proof and other required details.</p>
        </div>
      </section>
    </div>
  );
};

export default DivorceCertificate;
