import React from 'react';
import './style.css';
import Contact from '../../component/Contact/Contact';

const CompanyRegistration = () => {
  return (
    <section id="company-registration" className="company-section">
      <div className="company-container">
        <div className="company-details">
          <img
            src="https://www.getmyca.com/images/sliders/partnership%20firm.webp"
            alt="Company/LLP/Partnership Firm Registration"
            className="company-image"
          />
          <h1>Company/LLP/Partnership Firm Registration</h1>
          <p className="description">
            Registering a business is the first step toward establishing a legally recognized entity. Whether you are planning to start a **Private Limited Company**, **Limited Liability Partnership (LLP)**, or a **Partnership Firm**, proper registration ensures compliance with government regulations and gives your business credibility.
          </p>

          <h3>Types of Business Registrations:</h3>
          <ul>
            <li>
              <strong>Private Limited Company:</strong> Ideal for startups and small businesses seeking funding and limited liability.
            </li>
            <li>
              <strong>Limited Liability Partnership (LLP):</strong> Combines the flexibility of a partnership with the benefits of limited liability.
            </li>
            <li>
              <strong>Partnership Firm:</strong> A simple and affordable option for businesses managed by two or more partners.
            </li>
          </ul>

          <h3>Benefits of Business Registration:</h3>
          <ul>
            <li>Legal recognition and protection for the business.</li>
            <li>Limited liability for owners in LLPs and Companies.</li>
            <li>Easy access to funding and bank loans.</li>
            <li>Improved trust and credibility among customers and vendors.</li>
            <li>Eligibility to participate in government tenders and contracts.</li>
          </ul>

          <h3>Documents Required:</h3>
          <ul>
            <li>PAN card of all partners/directors.</li>
            <li>Aadhar card or other identity proof of partners/directors.</li>
            <li>Address proof (electricity bill, rental agreement, etc.).</li>
            <li>Passport-size photographs.</li>
            <li>Proof of registered office (electricity bill or NOC).</li>
            <li>Partnership deed (for Partnership Firm).</li>
          </ul>

          <h3>Steps for Registration:</h3>
          <ol>
            <li>Decide on the type of business entity (Company, LLP, or Partnership).</li>
            <li>Prepare required documents.</li>
            <li>Apply for Digital Signature Certificates (DSC) and Director Identification Numbers (DIN) if applicable.</li>
            <li>File incorporation documents with the Ministry of Corporate Affairs (MCA).</li>
            <li>Obtain the Certificate of Incorporation or Partnership Registration Certificate.</li>
            <li>Apply for PAN, TAN, and GST registration (if required).</li>
          </ol>

         <Contact/>
        </div>
      </div>
    </section>
  );
};

export default CompanyRegistration;
