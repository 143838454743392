import React from 'react';
import './style.css';
import Contact from '../../component/Contact/Contact';

const LegalHeirCertificate = () => {
  return (
    <div className="legal-heir-container">
      <header className="header3">
        <h1>Legal Heir Certificate</h1>
        <p>Everything you need to know about the process</p>
      </header>
      
      <section className="intro">
        <h2>What is a Legal Heir Certificate?</h2>
        <p>
          A Legal Heir Certificate is an official document issued by the government to establish the rightful heirs of a deceased person.
        </p>
      </section>

      <section className="requirements">
        <h2>Documents Required</h2>
        <ul>
          <li>Death Certificate of the deceased</li>
          <li>Identity proof of the applicant</li>
          <li>Family tree or genealogical details</li>
          <li>Proof of relationship with the deceased</li>
        </ul>
      </section>

      <section className="process">
        <h2>Process of Obtaining the Certificate</h2>
        <div className="steps">
          <div className="step">
            <h3>Step 1: Application</h3>
            <p>Submit the application form along with the required documents at the local authorities.</p>
          </div>
          <div className="step">
            <h3>Step 2: Verification</h3>
            <p>The submitted documents will be verified by the authorities to establish the legitimacy of the claim.</p>
          </div>
          <div className="step">
            <h3>Step 3: Issuance</h3>
            <p>Once the verification is complete, the Legal Heir Certificate will be issued to the rightful heirs.</p>
          </div>
        </div>
      </section>
   <Contact/>
      
    </div>
  );
};

export default LegalHeirCertificate;
